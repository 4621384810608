<!--                                       Categories -->
<template>
  <div class="columns">
    <div class="column is-4">
      <div class="p-2 text-white is-flex is-justify-content-space-between items-center is-uppercase" 
        :class="$root.modules[related_entity].class ??  'bg-sky-800'">
        <i :class="$root.modules[related_entity].icon ?? 'fa fa-sitemap mr-2 mt-1 text-sky-500'" class="mr-2"></i>
        {{ $root.modules[related_entity].title}}
        <select v-model="company_unit" 
          :class="$root.modules[related_entity].class ??  'bg-sky-800'"
          class="w-fit mr-2 ml-2" @change="getCategoriesOfCompanyUnit()">
          <option v-for="unit in company_units" :value="unit.code" :key="unit.id">{{ unit.name }}</option>
        </select>
        <a @click.prevent="masterCategory()">
          <i class="fa fa-plus-circle text-white"></i>
        </a>
      </div>
      <Tree :first_node="true" :module="$root.modules[related_entity]" v-if="categories.list" :items="categories.list" :flat="true" />
    </div>
    <div class="column is-8 "
      style="max-height: calc(100vh - 75px);
        overflow-y: auto;
        position: sticky;
        top: 0;
        ">
      <DaForm v-if="form" @submitted="submitCategory" @deleted="deleteCategory" :form="form" @close="form = null" :closes_form="true">
      </DaForm>
    </div>
  </div>
</template>

<script>
import CategoriesServices      from "@/services/Category";
import DaForm                  from "@/components/daform/index.vue";
import Tree                    from "@/components/Tree.vue";
import createAccessoryCategory from "./forms/accessory/create";
import createCategory          from "./forms/product/create";
import createCustomerCategory  from "./forms/customer/customer_create";
import createEmployeeCategory  from "./forms/employee/edit";
import createSupplierCategory  from "./forms/supplier/create";
import createWarehouseCategory from "./forms/warehouse/warehouse_create";
import editAccessoryCategory   from "./forms/accessory/edit";
import editCategory            from "./forms/product/edit";
import editCustomerCategory    from "./forms/customer/customer_edit";
import editEmployeeCategory    from "./forms/employee/edit";
import editSupplierCategory    from "./forms/supplier/edit";
import editWarehouseCategory   from "./forms/warehouse/warehouse_edit";
import helpers                 from "@/helpers.js";
import permissionMixin         from '@/mixins/permissions-mixin.js'
import socket                  from '@/socket.js';
import { onUnmounted, inject } from 'vue'
import { useCategoryStore }    from "@/stores/category";

export default {
  mixins: [permissionMixin],
  components: {
    Tree,
    DaForm,
  },
  setup() {
    let categories = useCategoryStore()
    const toast = inject('toast')
    socket.connect()
    socket.on("welcome", data => {
      console.log('welcome', data)
    })
    socket.on("propagate", data => {
      if (data.action == "create") {
        var a = categories.list
        var aa = categories.categories_attributes
        a.push(data.data[0])
        data.data[1].map(e => {
          aa.push(e)
        })
        categories.list = [...a]
        toast.success('U krijua nga ' + data.created_by.name)
      }
      if (data.action == "edit") {
        categories.list = [...categories.list.map(item => {
          if (item.guid == data.data.guid) {
            return data.data
          }
          return item
        })]
        toast.success('Klienti u modifikua nga ' + data.created_by.name)
      }

    })
    onUnmounted(() => {
      socket.off('welcome')
      socket.off('propagate')
      socket.disconnect()
    })
    return {
      categories
    }
  },
  data() {
    return {
      form: null,
      parent_cat: -1,
      related_entity: "",
      company_units: [],
      company_unit: null
    };
  },
  watch: {
    "$route.params"() {
      this.related_entity = this.$route.params.related_entity;
      if (this.related_entity) {
        this.getInitialData();
      }
    },
    "categories.current"() {
      if (this.categories.mode == "add") {
        // if ( 
        //     (this.am_allowed('product.can_create_product_category') && this.$route.path == '/categories/products') || 
        //     (this.am_allowed('customer.can_create_customer_category') && this.$route.path == '/categories/customers') ||
        //     (this.am_allowed('transactions.can_create_warehouse_category') && this.$route.path == '/categories/warehouses')
        // ){
        this.createCategory(
          this.categories.current.guid ? this.categories.current.guid : null
        );
        // } else {
        //   this.$toast.error('Ju nuk keni te drejta per te krijuar nje kategori.')
        // }

      }
      if (this.categories.mode == "edit") {
        // if (
        //      (this.am_allowed('product.can_edit_product_category') && this.$route.path == '/categories/products') || 
        //      (this.am_allowed('customer.can_edit_customer_category') && this.$route.path == '/categories/customers') ||
        //     (this.am_allowed('transactions.can_edit_warehouse_category') && this.$route.path == '/categories/warehouses')
        //    )
        // {
        this.editCategory(this.categories.current.guid);
        // } else {
        //   this.$toast.error('Ju nuk keni te drejta per te modifikuar nje kategori.')
        // }

      }
    },
  },
  methods: {
    checkIsDefault(unit){
      var code = "";
      this.company_units.map(u => {
        if(u.code == unit && u.is_default == 1){
          code = ""
        }
        else if(u.code == unit && u.is_default == 0){
          code = u.code
        }
      })
      return code;
    },
    async getCategoriesOfCompanyUnit() {
      this.categories.flat_list = [];
      this.categories.flat_list = await CategoriesServices.getCategories(this.related_entity, this.checkIsDefault(this.company_unit));
      this.categories.list = [];
      this.categories.list = await CategoriesServices.getTreeCategories(this.categories.flat_list, "id");
    },
    async getInitialData(all = true) {
      if (!this.related_entity) return;
      if(all){
        this.categories.flat_list = [];
        this.categories.flat_list = await CategoriesServices.getCategories( this.related_entity);
        this.categories.list = [];
        this.categories.list = await CategoriesServices.getTreeCategories( this.categories.flat_list, "id");
        this.company_units = await CategoriesServices.getCompanyUnits();
        if (this.company_units.length > 0) {
          this.company_unit = this.company_units.find(c => c.is_default == 1).code
        }
      }
      /* let attr_list = await CategoriesServices.getAttributesList(
        this.parent_cat,
        this.related_entity
      );
      this.categories.attributes = attr_list.map((d) => {
        d.id = d.guid;
        d.label = d.name;
        return d;
      });


      let entity_attributes = await CategoriesServices.getEntityAttributes(
        this.related_entity == 'accessories' ? 'products' : this.related_entity
      );
      this.categories.categories_attributes = entity_attributes.map((d) => {
        d.id = d.guid;
        d.label = d.name;
        return d;
      });

      if (this.related_entity == 'products') {

        let entity_accessories = await CategoriesServices.getEntityAttributes(
          'accessories'
        );
        this.categories.categories_accessories = entity_accessories.map((d) => {
          d.id = d.guid;
          d.label = d.name;
          return d;
        });

      }

      if (this.related_entity == 'accessories' || this.related_entity == 'products') {
        // get connection of product category and service type
        let entity_service_types = await CategoriesServices.getEntityAttributes(
          'services'
        );
        this.categories.categories_service_types = entity_service_types.map((d) => {
          d.id = d.guid;
          d.label = d.name;
          return d;
        });

      }*/
    },
    deleteCategory(f) {
      this.categories.flat_list = [
        ...this.categories.flat_list.filter((a) => {
          var found = false;
          f.map((ff) => {
            if (ff.descendant == a.guid) found = true;
          });
          return !found;
        }),
      ];
      this.categories.list = helpers.toTree(this.categories.flat_list, "id");
    },
    async submitCategory(f) {
      // set parent category
      f = {
        ...f,
        parent_id: this.parent_cat,
        unit: this.checkIsDefault(this.company_unit),
        guid:
          this.categories.current.guid &&
            this.categories.current.guid != this.parent_cat
            ? this.categories.current.guid
            : null,
      };
      // send to api

      let category_process = await CategoriesServices.getCategoryProcess(
        f,
        this.categories.mode,
        this.related_entity
      );
      this.getInitialData(false);


      var notify_text = "";

      if (this.categories.mode == "add") {
        var new_category_process = {
          ...category_process[0],
          id: category_process[0].guid,
          label: category_process[0].name,
          collapsed: true,
        };
        this.categories.flat_list.push(new_category_process);
        this.categories.list = [];
        this.categories.list = helpers.toTree(this.categories.flat_list, "id");
        // this.categories.list = await CategoriesServices.getTreeCategories( this.categories.flat_list, "id");

        // add accessories, services
        category_process[1].map((el) => {
          this.categories.categories_attributes.push(el);
        });
        category_process[2].map((el) => {
          this.categories.categories_accessories.push(el);
        });
        category_process[3].map((el) => {
          this.categories.categories_service_types.push(el);
        });
        socket.emit("propagate", { entity: this.related_entity, action: "create", data: [category_process[0], category_process[1]] })
        notify_text = "Kategoria u krijua me sukses!";
      }
      // 
      else if (this.categories.mode == "edit") {

        this.categories.categories_attributes = [...this.categories.categories_attributes.filter(c => c.category_id != category_process[0].guid)]
        category_process[1].map((el) => {
          this.categories.categories_attributes.push(el);
        });
        this.categories.categories_accessories = [...this.categories.categories_accessories.filter(c => c.product_category_id != category_process[0].guid)]
        category_process[2].map((el) => {
          this.categories.categories_accessories.push(el);
        });
        this.categories.categories_service_types = [...this.categories.categories_service_types.filter(c => c.category_id != category_process[0].guid)]
        category_process[3].map((el) => {
          this.categories.categories_service_types.push(el);
        });

        var cat_process = {
          ...category_process[0],
          id: category_process[0].guid,
          label: category_process[0].name
        }

        this.categories.flat_list = this.categories.flat_list.map(a => {
          // When the category to be updated is found
          if (a.guid === category_process[0].guid) {
            return { ...a, ...cat_process };
          }
          // For all other categories that have the same parent
          else if (a.parent_id === category_process[0].parent_id) {
            const oldSortNr = category_process[5];
            const newSortNr = category_process[0].sort_nr;

            // When moving up (sort order is decreasing)
            if (newSortNr < oldSortNr && a.sort_nr >= newSortNr && a.sort_nr < oldSortNr) {
              return { ...a, sort_nr: a.sort_nr + 1 };
            }
            // When moving down (sort order is increasing)
            else if (newSortNr > oldSortNr && a.sort_nr > oldSortNr && a.sort_nr <= newSortNr) {
              return { ...a, sort_nr: a.sort_nr - 1 };
            }
          }
          // For categories that are not affected
          return a;
        });



        this.categories.list = helpers.toTree(this.categories.flat_list, "id");
        if (this.related_entity == 'accessories' || this.related_entity == 'products') {
          // get connection of product category and service type
          let entity_service_types = await CategoriesServices.getEntityAttributes(
            'services'
          );
          this.categories.categories_service_types = entity_service_types.map((d) => {
            d.id = d.guid;
            d.label = d.name;
            return d;
          });

        }

        socket.emit("propagate", { entity: this.related_entity, action: "edit", data: category_process[0] })
        notify_text = "Kategoria u modifikua me sukses!";
      }

      this.$toast.success(notify_text);
      this.form = null;
    },
    async editCategory(parent_category) {
      this.form = null;
      this.form = { loading: true };
      this.parent_cat = this.categories.current.parent_id;


      setTimeout(async () => {
        if (this.related_entity == 'customers') {
          this.form = await editCustomerCategory(parent_category)
        }
        else if (this.related_entity == 'products') {
          this.form = await editCategory(parent_category);
        }
        else if (this.related_entity == 'warehouses') {
          this.form = await editWarehouseCategory();
        }
        else if (this.related_entity == 'accessories') {
          this.form = await editAccessoryCategory(parent_category);
        }
        else if (this.related_entity == 'suppliers') {
          this.form = await editSupplierCategory(parent_category);
        }
        else if (this.related_entity == 'suppliers') {
          this.form = await editEmployeeCategory(parent_category);
        }
      });
    },
    async createCategory(parent_category) {
      this.form = null;
      this.form = { loading: true };
      // set parent category
      this.parent_cat = parent_category;

      // // Get attributes list
      // let attr_list = await CategoriesServices.getAttributesList(
      //   -1,
      //   this.related_entity
      // );
      // // Assign the attribute to categories
      // this.categories.attributes = attr_list.map((d) => {
      //   d.id = d.guid;
      //   d.label = d.name;
      //   return d;
      // });

      // // Get inherited attributes list
      // var parent_attr_list = await CategoriesServices.getParentAttributeList(
      //   parent_category,
      //   this.related_entity,
      //   1
      // );
      // // Assign to category 
      // this.categories.inherited_attributes = parent_attr_list.map((d) => {
      //   d.id = d.guid;
      //   d.label = d.name;
      //   return d;
      // });

      // // Get accessories list
      // let accessories_list = await CategoriesServices.getCategories("accessories")
      // // Assign the accessories to categories
      // this.categories.accessories = helpers.toTree(accessories_list, "id")

      // var parent_accessories_list = await CategoriesServices.getParentAccessoryList(
      //   parent_category,
      //   this.related_entity,
      //   1
      // );
      // this.categories.inherited_accessories = parent_accessories_list.map((d) => {
      //   d.id = d.guid;
      //   d.label = d.name;
      //   return d;
      // });


      // load form

      setTimeout(async () => {
        if (this.related_entity == 'customers') {
          this.form = await createCustomerCategory(parent_category)
        }
        else if (this.related_entity == 'products') {
          this.form = await createCategory(parent_category)
        }
        else if (this.related_entity == 'warehouses') {
          this.form = await createWarehouseCategory()
        }
        else if (this.related_entity == 'accessories') {
          this.form = await createAccessoryCategory(parent_category);
        }
        else if (this.related_entity == "suppliers") {
          this.form = await createSupplierCategory()
        }
        else if (this.related_entity == "employees") {
          this.form = await createEmployeeCategory()
        }
      });
    },
    masterCategory() {
      // if (
      //     (this.am_allowed('product.can_create_product_category') && this.$route.path == '/categories/products') || 
      //     (this.am_allowed('customer.can_create_customer_category') && this.$route.path == '/categories/customers') ||
      //     (this.am_allowed('transactions.can_create_warehouse_category') && this.$route.path == '/categories/warehouses')
      // ){
      this.categories.current = {};
      this.categories.mode = "add";
      // } else {
      //   this.$toast.error('Ju nuk keni te drejta per te krijuar nje kategori.')
      // }
    },
  },
  async created() {
    // if(
    //   this.related_entity == 'products' && !this.am_allowed('product.can_see_product_category') ||
    //   this.related_entity == 'customers' && !this.am_allowed('customer.can_see_customer_category') ||
    //   this.related_entity == 'warehouses' && !this.am_allowed('transactions.can_see_warehouse_category')

    // ){
    //   window.location.href = '/home'
    // }
    this.related_entity = this.$route.params.related_entity;
    this.getInitialData();
  },
  onBeforeDestroy() {
    socket.disconnect();
  },
};
</script>
